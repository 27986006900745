import React from 'react';

import '../../styles/root.scss';
import '../../styles/base.scss';
import './Layout.style.scss';

import LogoImg from '../../images/logo.svg';

const Layout = ({children, footer}) => {

    const MdfxLink = ({children}) => (
        <a href="https://www.madfoxdesign.cz" target="_blank" rel="noopener noreferrer">{children}</a>
    );

    const defaultFooter = () => (
        <>
            <div className="layout__footer-logo">
                <MdfxLink>
                    <img src={LogoImg} alt=""/>
                </MdfxLink>
            </div>
            <p className="layout__footer-text">
                Tato stránka je hostována společností <strong><MdfxLink>MadFox design s.r.o.</MdfxLink></strong>
            </p>
        </>
    );

    return (
        <div className="layout">
            <div className="layout__header" />
            <div className="layout__body">
                {children}
            </div>
            <div className="layout__footer">
                {footer || defaultFooter()}
            </div>
        </div>
    )
};

export default Layout;
